* {
  margin: 0;
  padding: 0;
}

.homeContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: black;
  color: #f6f6ff;
  width: 100vw;
  /* height: 66px; */
}

.copyright {
  /* text-align: center;
  vertical-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* bottom: 2000px; */
  /* margin-top: 10px; */
  font-size: 25px;
  color: white;
}

@media (max-width: 48em) {
  .homeContainer {
    /* margin-top: 50px; */
    width: 100vw;
    text-align: center;
    position: absolute;
    bottom: 0;
  }

  .footerContainer {
    /* position: absolute; */
    flex-direction: column;
  }
  .footerContainer .copyright {
    position: relative;
    justify-content: center;
    /* bottom: -50px; */
  }

  .test {
    font-size: 1rem;
    color: white;
  }
}
