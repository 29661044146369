.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .navbar {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
