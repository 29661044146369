/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

.Image {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

img {
  display: block;
  cursor: pointer;
}

.pictureContainer {
  margin: auto;
  width: 100%;
  max-width: 540px;
}

.york {
  width: 500px;
  height: 500px;
}

.pictureContainer .selected {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border: 4px solid red;
}

.pictureContainer .imgContainer {
  width: 100%;
  display: flex;
  justify-items: space-between;
  flex-wrap: wrap;
  /* padding: 40px; */
  margin: 5px;
}

.pictureContainer .imgContainer img {
  width: 100px;
  height: 100px;
  margin: 5px 0;
}
