.buyContainer {
  width: 100vw;
}

.carousel__container {
  /* max-width constrains the width of our carousel to 550, but shrinks on small devices */
  max-width: 550px;
  /* height: 50vh; */
  margin: 1rem 0 0 1rem;
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.carousel {
  width: 450px;
}
.pictures {
  max-height: 400px;
  max-width: 450px;
}

.example {
  padding: 0;
  width: 300px;
  display: block;
  cursor: pointer;
  overflow: hidden;
}

.back-btn {
  background-color: black;
  border: 1px solid black;
  margin-top: -5em;
  color: white;
  width: 10em;
  height: 3em;
  /* position: relative;
  top: 0; */
}

.next-btn {
  background-color: black;
  border: 1px solid white;
  margin-top: -5em;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 10em;
  height: 3em;
  color: white;
  /* position: absolute; */
}

.row {
  display: flex;
  /* flex-flow: row nowrap; */
  justify-content: space-evenly;
  align-items: center;
  /* height: 100vh; */
}

.column {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .row {
    display: flex;
    flex-direction: column;
  }
  .column {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
