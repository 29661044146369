.test {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
input {
  display: block;
  min-width: 90%;
  margin: 0.5em;
  padding: 0.5em;
  width: 15em;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
}

input:hover {
  border-color: gold;
}
.form-submit-button {
  display: flex;
  height: 50px;
  width: 120px;
  background-color: silver;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 0.75em;
  border-radius: 50px;
  margin: 20px auto;
  border: none;
  font-family: "Inknut Antiqua", serif;
}
.form-submit-button:hover {
  background-color: white;
  color: black;
}

select {
  background-color: silver;
  margin: 10px 0;
  font-family: "Inknut Antiqua", serif;
  border-radius: 50px;
}

.testy {
  display: flex;
  flex-direction: row;
  width: 5rem;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.selectors {
  display: flex;
  flex-direction: row;
}

select {
  margin: 15px;
}

@media only screen and (max-width: 640px) {
  .testy {
    display: flex;
    flex-direction: column;
  }

  .selectors {
    display: flex;
    flex-direction: column;
    width: 10rem;
    justify-content: space-between;
  }
}
