* {
  margin: 0;
  padding: 0;
}

footer {
  position: absolute;
  left: 0;
  bottom: -50px;
  background-color: #000;
  /* border: 3px solid #000; */
  color: #f6f6ff;
  width: 100vw;
  height: 66px;
}

.copyright {
  /* text-align: center;
  vertical-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 10px; */
  font-size: 25px;
}

@media (max-width: 48em) {
  footer {
    margin-top: 50px;
    width: 100vw;
    text-align: center;
    bottom: -100px;
  }

  .footerContainer {
    /* position: absolute; */
    flex-direction: column;
    bottom: 100px;
  }
  .footerContainer .copyright {
    position: relative;
    justify-content: center;
    /* bottom: -50px; */
    font-size: 1rem;
  }
}
