.sliderContainer {
  width: 100vw;
  /* height: 95%; */
  display: flex;
  justify-content: center;
  /* margin-left: 420px; */
  height: 40vh;
  margin-top: 10px;
}

section {
  position: absolute;
  width: 100vw;
  height: 80%;
  display: flex;
}

section .slide {
  position: relative;
  background: rgb(12, 11, 11);
  height: 100%;
  flex: 1;
  border-right: 2px solid #000;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

section .slide:last-child {
  border-right: none;
}

section .slide:hover {
  flex-grow: 5;
}

section .slide:nth-child(1) {
  background: url(../../img/silverFront.jpg);
  background-position: center center;
}

section .slide:nth-child(2) {
  background: url(../../img/blackBack.jpg);
  background-position: center center;
}

section .slide:nth-child(3) {
  background: url(../../img/blackFront.jpg);
  background-position: center center;
}

section .slide:nth-child(4) {
  background: url(../../img/blackBack1.jpg);
  background-position: center center;
}

section .slide .content {
  position: absolute;
  bottom: 0;
  margin: 40px;
  padding: 30px;
  background: #000;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: 0s;
  transform: translateY(100px);
}

section .slide:hover .content {
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
  transition-delay: 0.5s;
  transform: translateY(0);
}

a {
  text-decoration: none;
  color: white;
}
a:hover {
  color: gold;
}

@media only screen and (max-width: 640px) {
  .sliderContainer {
    display: flex;
    justify-content: center;
    height: 100vh;
  }
  section {
    position: absolute;
    flex-direction: column;
    height: 100vh;
  }

  section .slide {
    height: 50vh;
  }

  section .slide .content {
    margin-top: 50px;
    height: 35vh;
  }

  h2 {
    font-size: 1rem;
  }

  p {
    font-size: 0.65rem;
  }
}
