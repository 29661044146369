.text {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.bottom {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1000px;
}

@media only screen and (max-width: 640px) {
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
  }

  .bottom {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -10000px;
  }
}
